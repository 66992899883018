<template>
  <moe-page class="category-add" title="分类添加">
    <moe-form ref="categoryForm" :model="categoryModel" :rules="rules" :defaultVerify="false">
      <el-form-item label="上级分类规则" prop="parentId">
        <moe-cascader placeholder="请选择上级分类规则" v-model="categoryModel.parentId" :options="categoryList" :checkStrictly="true"></moe-cascader>
      </el-form-item>

      <el-form-item label="分类名称" prop="name">
        <el-input v-model.trim="categoryModel.name" placeholder="请输入分类名称" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="分类图片" prop="coverUrl">
        <moe-upload-file
          v-model="categoryModel.coverUrl"
          ossKey="SHOP_MEDIA"
          upload-text="上传分类图片"
          @change="() => $refs.categoryForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="排序">
        <el-input :value="categoryModel.sort" @input="(value) => categoryModel.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入分类排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <moe-radio-group v-model="categoryModel.status" :radioBoxList="statusList"></moe-radio-group>
      </el-form-item>

      <el-form-item label="规格" prop="specList" v-if="categoryModel.parentId.length === 2">
        <moe-form ref="specsForm" class="default-form" :model="categoryModel" :showBack="false" :showTool="false" style="padding: 0;" :defaultVerify="false">
          <div v-for="(item, index) in categoryModel.specList" :key="index">
            <el-form-item style="margin-bottom: 22px;" :prop="`specList.${index}.name`" :rules="specsRules.name">
              <div class="df dac fww">
                <div class="df1 mr-10">
                  <el-select v-model="item.id" filterable placeholder="请选择规格" @change="(val) => handleChange(val, item, index)">
                    <el-option
                      v-for="(specsItem, specsIndex) in specsList"
                      :key="specsIndex"
                      :label="specsItem.name"
                      :value="specsItem.id">
                    </el-option>
                  </el-select>
                </div>
                <el-button type="warning" size="small" icon="el-icon-edit" @click="specsValueDetail(item.id, item, index)">修改规格值</el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" @click="handleDelete(item, index)">删除</el-button>
              </div>
            </el-form-item>

            <!-- :prop="`specList.${index}.valueList`" :rules="rules.valueList" -->
            <el-form-item style="margin-bottom: 22px;" v-if="item.name">
              <div class="df aic fww">
                <div class="specs-item" v-for="(valueListItem, valueListIndex) in item.valueList" :key="valueListIndex">{{ valueListItem.value }}</div>
              </div>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button :disabled="disabledComputed" size="medium" icon="el-icon-plus" @click="insert()">添加规格</el-button>
          </el-form-item>
        </moe-form>
      </el-form-item>

      <el-form-item label="备注">
        <el-input type="textarea" autosize placeholder="输入备注" v-model="categoryModel.remark"></el-input>
      </el-form-item>

      <el-button slot="tool" icon="el-icon-upload2" type="primary" :loading="categoryLoad" @click="submit()">{{ categoryLoad ? '保存中' : '保存' }}</el-button>
    </moe-form>

    <moe-dialog :show="dialogModel.show" title="选择规格值" width="50%" @close="dialogClose()">
      <div class="dialog_content">
        <moe-form class="default-form" :showTool="false" :showBack="false" :inline="true" style="padding: 0;" v-if="categoryModel.specList.length && categoryModel.specList[specListIndex] && categoryModel.specList[specListIndex].checkList.length">
          <el-form-item>
            <el-checkbox :indeterminate="dialogModel.isIndeterminate" v-model="dialogModel.checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </el-form-item>
        </moe-form>

        <div class="dialog_content_checkbox" v-if="categoryModel.specList.length && categoryModel.specList[specListIndex]">
          <moe-checkbox-group
            @change="handleCheckboxChange"
            v-model="categoryModel.specList[specListIndex].checkSelectList"
            :checkboxList="categoryModel.specList[specListIndex].checkList"
            :border="true">
          </moe-checkbox-group>

          <moe-form class="default-form" :model="dialogModel" :showTool="false" :showBack="false" :inline="true" style="padding: 0;">
            <el-form-item prop="addCheckValue" :rules="specsRules.addCheckValue">
              <el-input style="width: 200px;" clearable v-model.trim="dialogModel.addCheckValue" placeholder="输入规格值" @change="(value) => handleInputChange(value)"></el-input>
            </el-form-item>
          </moe-form>
        </div>
      </div>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button type="primary" @click="insertValueList()">确 定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'CategoryAdd',
  data() {
    const verify = this.$moe_verify.verifyForm
    const checkValueList = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!this.categoryModel.specList.length) {
          return callback();
        }
        if (this.categoryModel.specList[this.specListIndex].checkList.filter((item) => item.value === value).length >= 1) {
          return callback(new Error(`规格值${value}重复`));
        }
        callback();
      }
    }
    return {
      categoryList: [],
      categoryModel: {
        id: '',               // 分类id
        parentId: [],         // 父ID
        level: 0,             // 分类级别
        delFlag: 0,           // 删除状态 0：正常 1：删除
        name: '',             // 分类名称
        coverUrl: '',         // 图片地址
        sort: '',             // 排序
        status: 1,            // 等级：1.一级、2.二级、3.三级
        specList: [{          // 分类规格集合
          name: '',
          id: '',
          valueList: [],
          checkList: [],
          checkSelectList: [],
          oldCheckSelectList: [],
        }],
        remark: '',           // 备注
      },
      rules: {
        parentId: verify.isEmpty('请选择上级分类规则'),
        name: verify.isEmpty('请输入分类名称'),
        coverUrl: verify.isEmpty('请上传分类图片'),
        level: verify.isEmpty('请选择分类类型'),
        status: verify.isEmpty('请选择状态'),
        specList: verify.isEmpty('请添加规格'),
        valueList: verify.isEmpty('请添加规格值'),
      },
      specsRules: {
        name: verify.isEmpty('请输入规格名称'),
        valueList: [{ required: true, message: '请选择规格值', trigger: ['blur', 'change'] }],
        addCheckValue: [{ required: true, validator: checkValueList, trigger: ['blur', 'change'] }],
      },
      statusList: [
        { label: '启用', value: 1 },
        { label: '禁用', value: 2 },
      ],
      specsList: [],
      specListIndex: 0,
      categoryLoad: false,
      dialogModel: {
        show: false,
        checkAll: false,
        addCheckValue: '',
        isIndeterminate: false,
      }
    }
  },
  computed: {
    disabledComputed() {
      if (!this.categoryModel.specList.length) {
        return false;
      }
      return this.categoryModel.specList.filter(({ name }) => !name).length ? true : false;
    },
  },
  methods: {
    async submit() {
      let categoryForm = new Promise((resolve, reject) => {
        this.$refs.categoryForm.validate(() => {
          resolve()
        }).catch(() => {
          reject()
        })
      });

      let specsForm = new Promise((resolve, reject) => {
        if (this.categoryModel.parentId.length === 2) {
          this.$refs.specsForm.validate(() => {
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      });
      await Promise.all([categoryForm, specsForm]).then(() => {
        this.categoryLoad = true
        let level = 1, specList = [];
        if (this.categoryModel.parentId.length === 1) {
          if (this.categoryModel.parentId[0] === 0) {
            level = 1;
          } else {
            level = 2;
          }
        } else {
          level = 3;
        }
        if (level === 3) {
          specList = this.categoryModel.specList.map(({ name, valueList }) => {
            return {
              id: '',
              categoryId: '',
              name,
              valueList: valueList.map((item) => {
                return {
                  categoryId: '',
                  id: '',
                  specId: '',
                  value: item.value,
                }
              })
            }
          })
        }
        this.$moe_api.GOODS_API.insertCategory({
          ...this.categoryModel,
          level,
          parentId: this.categoryModel.parentId[this.categoryModel.parentId.length - 1],
          specList,
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('保存成功');
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message)
          }
        }).finally(() => {
          this.categoryLoad = false
        })
      }).catch(() => {
        this.$moe_msg.warning('请完善信息！')
      });
    },
    /** 新增分类规格 */
    insert() {
      this.categoryModel.specList.push({
        name: '',
        id: '',
        valueList: [],
        checkList: [],
        checkSelectList: [],
        oldCheckSelectList: [],
      })
    },
    /** 规格选择 */
    handleChange(value, item, index) {
      if (value) {
        if (this.categoryModel.specList.filter(({ id }) => id === value).length > 1) {
          item.id = item.name = '';
          this.categoryModel.specList.splice(index, 1);
          return this.$moe_msg.warning(`该规格“${this.specsList.find(({ id }) => id === value).name}”已经存在`);
        }
        if (item.name && item.name !== this.specsList.find(({ id }) => id === value).name) {
          item.checkList.splice(0);
          item.checkSelectList.splice(0);
          item.oldCheckSelectList.splice(0);
          item.valueList.splice(0);
          this.dialogModel.isIndeterminate = false;
          this.dialogModel.checkAll = false;
        }
        item.name = this.specsList.find(({ id }) => id === value).name;
        this.specsValueDetail(value, item, index);
      }
    },
    handleDelete(item, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${item.name}”吗？`, () => {
        this.$moe_msg.success('删除成功');
        this.categoryModel.specList.splice(index, 1);
      })
    },
    handleInputChange(value) {
      if (!value) {
        return true;
      }

      if (this.categoryModel.specList[this.specListIndex].checkList.filter((item) => item.value === value).length >= 1) {
        return false;
      } else {
        this.categoryModel.specList[this.specListIndex].checkList.push({
          categoryId: '',
          id: '',
          specId: '',
          value
        });

        this.dialogReset(this.categoryModel.specList[this.specListIndex])
        this.dialogModel.addCheckValue = '';
      }
    },
    /** 选择规格值 确定 */
    insertValueList() {
      this.categoryModel.specList[this.specListIndex].valueList = this.categoryModel.specList[this.specListIndex].checkSelectList.map((value) => {
        let findItem = this.categoryModel.specList[this.specListIndex].checkList.find((item) => value === item.value);
        return {
          value: findItem.value,
          id: findItem.id,
          categoryId: findItem.categoryId,
          specId: findItem.specId,
        }
      });
      this.categoryModel.specList[this.specListIndex].oldCheckSelectList = JSON.parse(JSON.stringify(this.categoryModel.specList[this.specListIndex].checkSelectList));
      this.dialogModel.show = false;
    },
    /** 选择规格值 重置 */
    dialogReset(item) {
      let checkedCount = item.checkSelectList.length;
      let total = item.checkList.length;
      this.dialogModel.checkAll = checkedCount === total;
      this.dialogModel.isIndeterminate = checkedCount > 0 && checkedCount < total;
    },
    /** 选择规格值 关闭 取消 */
    dialogClose() {
      this.categoryModel.specList[this.specListIndex].checkSelectList = JSON.parse(JSON.stringify(this.categoryModel.specList[this.specListIndex].oldCheckSelectList));
      this.dialogModel.addCheckValue = '';
      this.dialogModel.show = false;
    },
    /** checkbox 全选监听 */
    handleCheckAllChange(value) {
      this.categoryModel.specList[this.specListIndex].checkSelectList = value ? this.categoryModel.specList[this.specListIndex].checkList.map((item) => item.value) : [];
      this.dialogModel.isIndeterminate = false;
    },
    /** checkbox 单选监听 */
    handleCheckboxChange(value) {
      let checkedCount = value.length;
      this.dialogModel.checkAll = checkedCount === this.categoryModel.specList[this.specListIndex].checkList.length;
      this.dialogModel.isIndeterminate = checkedCount > 0 && checkedCount < this.categoryModel.specList[this.specListIndex].checkList.length;
    },
    /** 获取规格值 */
    specsValueDetail(specsId, item, index) {
      this.specListIndex = index;
      if (item.checkList.length) {
        this.dialogReset(item);
        this.dialogModel.show = true;
      } else {
        if (!specsId) {
          return this.$moe_msg.warning('请选择规格')
        }
        this.$moe_api.GOODS_API.specsValueDetail({ specsId }).then((data) => {
          this.dialogModel.show = true;
          item.checkList = data.result.map(({ value }) => {
            return {
              categoryId: '',
              id: '',
              specId: '',
              value
            }
          })
          this.dialogReset(item);
        })
      }
    },
    /** 获取通用规格 */
    querySpecsList() {
      this.$moe_api.GOODS_API.querySpecsList({ pageNum: 1, pageSize: 9999 }).then((data) => {
        if (data.code === 200) {
          this.specsList = data.result.map((item) => {
            return {
              ...item,
              value: item.name,
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取分类列表 */
    categoryListTree() {
      this.$moe_api.GOODS_API.categoryListTree().then((data) => {
        if (data.code === 200) {
          this.categoryList = data.result.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
              children: item.childrens && item.childrens.map((ItemchildItem) => {
                return {
                  ...ItemchildItem,
                  label: ItemchildItem.name,
                  value: ItemchildItem.id,
                }
              })
            }
          });
          this.categoryList.unshift({ label: '根目录', value: 0 });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.querySpecsList()
    this.categoryListTree()
  }
}
</script>

<style lang="scss" scoped>
.category-add {
  .dialog_content {
    margin: 20px auto;
    width: 80%;
    .dialog_content_checkbox {
      position: relative;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  .specs-item {
    padding: 7px 10px 7px 10px;
    border: 1px solid $--color-info;
    border-radius: 4px;
    margin: 0 20px 20px 0;
    max-height: 36px;
    font-size: 14px;
    line-height: 1;
  }
}
</style>